import React from "react";
import "./style.css"; // Importer le fichier CSS pour les styles

const BlogText = ({ course }) => {
  const parseHTMLContent = (htmlString) => {
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(htmlString, "text/html");
    
    // Ajouter une classe aux images
    const images = parsedHTML.querySelectorAll('img');
    images.forEach(img => img.classList.add('blog-image'));

    return parsedHTML.body.innerHTML;
  };

  return (
    <div className="mt-1 pt-2">
      {course ? (
        <div className="ms-lg-4 mt-1 mt-lg-0">
          <div className="blog-post mb-5">
            <div className="text-center mb-5">
              <h5 className="text-center mb-1">{course.title}</h5>
            </div>
            {course.sections.map((section) => (
              <div key={section.id}>
                <h6>{section.title}</h6>
                <hr />
                <div
                  dangerouslySetInnerHTML={{
                    __html: parseHTMLContent(section.content),
                  }}
                />
              </div>
            ))}

            <p className="text-muted mb-2">
              <span dangerouslySetInnerHTML={{ __html: course.description }} />
            </p>
          </div>
        </div>
      ) : (
        <p>Veuillez sélectionner un cours à gauche pour voir les détails.</p>
      )}
    </div>
  );
};

export default BlogText;
