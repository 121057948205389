import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

//Import Images
import JobDetailImage from "../../../assets/images/job-detail.jpg";
// import { Link } from "react-router-dom";

const JobDetailsDescription = ({ selectedDetails }) => {
  const renderResponsibilities = () => {
    if (!selectedDetails.responsibility) return null;

    // Split responsibilities by newline character '\n'
    const responsibilities = selectedDetails.responsibility.split('\n');

    return (
      <div className="job-detail-desc mt-2">
        {responsibilities.length > 0 && (
          <ul className="job-detail-list list-unstyled mb-0 text-muted">
            {responsibilities.map((resp, index) => (
              <li key={index}>
                <i className="uil uil-circle"></i> {resp.trim()}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const renderQualifications = () => {
    if (!selectedDetails.qualification) return null;

    // Split qualifications by newline character '\n'
    const qualifications = selectedDetails.qualification.split('\n');

    return (
      <div className="job-detail-desc mt-2">
        {qualifications.length > 0 && (
          <ul className="job-detail-list list-unstyled mb-0 text-muted">
            {qualifications.map((qualification, index) => (
              <li key={index}>
                <i className="uil uil-circle"></i> {qualification.trim()}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  const renderTechnicalSkills = () => {
    if (!selectedDetails.technicalSkills) return null;

    // Split technical skills by newline character '\n'
    const technicalSkills = selectedDetails.technicalSkills.split('\n');

    return (
      <div className="job-details-desc mt-2">
        {technicalSkills.length > 0 && (
          <ul className="job-detail-list list-unstyled mb-0 text-muted">
            {technicalSkills.map((skill, index) => (
              <li key={index}>
                <i className="uil uil-circle"></i> {skill.trim()}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <Card className="job-detail overflow-hidden">
        <div>
          <img
            src={selectedDetails.company.coverPhoto || JobDetailImage}
            alt=""
            className="img-fluid"
          />
          <div className="job-details-compnay-profile">
            <img
              src={
                selectedDetails.company.logo ||
                "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
              }
              alt=""
              width="100"
              height="100"
              style={{ border: "2px solid black", borderRadius: "10px" }}
              className="img-fluid rounded-3"
            />
          </div>
        </div>
        <CardBody className="p-4">
          <div>
            <Row>
              <Col md={8}>
                <h5 className="mb-1">{selectedDetails.title}</h5>
                <ul className="list-inline text-muted mb-0">
                  <li className="list-inline-item">
                    <i className="mdi mdi-account"></i>{" "}
                    {selectedDetails.positionAvailable} Poste
                    {selectedDetails.positionAvailable > 1 ? "s" : ""} à
                    pourvoir
                  </li>
                </ul>
              </Col>
            </Row>
          </div>

          <div className="mt-4">
            <Row className="g-2">
              <Col lg={3}>
                <div className="border rounded-start p-3">
                  <p className="text-black mb-0 fs-13">
                    Experience :
                    <span className="fs-12 fw-bold">
                      {" "}
                      {selectedDetails.experience} An(s)
                    </span>
                  </p>
                </div>
              </Col>
              <Col lg={4}>
                <div className="border p-3">
                  <p className="text-black fs-13 mb-0">
                    Contrat :
                    {selectedDetails.contractType.map((contract) => (
                      <span key={contract} className="fs-12 fw-bold ms-2">
                        {contract}
                      </span>
                    ))}
                  </p>
                </div>
              </Col>
              <Col lg={5}>
                <div className="border rounded-end p-3">
                  <p className="text-black fs-13 mb-0">
                    Salaire :
                    <span className="fs-12 fw-bold">
                      {` ${selectedDetails.minSalary} ${selectedDetails.currency} - ${selectedDetails.maxSalary} ${selectedDetails.currency}`}
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="mt-4">
            <h5 className="mb-3">Description de l'offre</h5>
            <div className="job-detail-desc">
              <p className="text-muted mb-0">{selectedDetails.description}</p>
            </div>
          </div>

          <div className="mt-4">
            <h5 className="mb-3">Responsabilités</h5>
            {renderResponsibilities()}
          </div>

          <div className="mt-4">
            <h5 className="mb-3">Qualification </h5>
            {renderQualifications()}
          </div>

          <div className="mt-4">
            <h5 className="mb-3">Compétences Techniques</h5>
            {renderTechnicalSkills()}
            <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
              {selectedDetails.skills.map((skill, index) => (
                <span key={index} className="badge bg-primary">
                  {skill.name}
                </span>
              ))}
            </div>
          </div>
          <div className="mt-4">
            <h5 className="mb-3">Langues parlées</h5>
            <div className="job-details-desc">
              <ul className="job-detail-list list-unstyled mb-0 text-muted">
                {selectedDetails.languages.map((language, index) => (
                  <li key={index}>
                    <i className="uil uil-circle"></i> {language.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default JobDetailsDescription;
