import React, { useEffect, useRef } from 'react';
import { Col, Container, Spinner } from 'reactstrap';
import Masonry from 'react-masonry-component';
import { useDispatch, useSelector } from 'react-redux';

import { fetchCompaniesPaged } from '../../../redux/company/action';
import CompanyCard from './CompanyCard';
import Section from './Section';
import Pagination from './Pagination';

const CompanyList = () => {
  document.title = 'Company List | RecruTech';
  const firstCardRef = useRef(null);

  const dispatch = useDispatch();
  const { company, loading } = useSelector(state => state.companyReducer);

  const setCurrentPage = page => {
    dispatch(fetchCompaniesPaged(page));
  };

  useEffect(() => {
    dispatch(fetchCompaniesPaged(company.currentPage));
    window.scrollTo(0, 0);
  }, [dispatch, company.currentPage]);

  // Vérifiez que `company.content` est défini avant d'essayer de le trier
  const sortedCompanies = company.content ? [...company.content].sort((a, b) => b.numberOfJobs - a.numberOfJobs) : [];

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          {loading ? (
            <div className="text-center mt-5">
              <Spinner color="primary" style={{ width: '3rem', height: '3rem' }} />
            </div>
          ) : (
            <>
              {/* {company.totalElements} entreprises à découvrir */}
              <Masonry className="row">
                {sortedCompanies.map((item, index) => (
                  <Col
                    key={item.id} // Utiliser l'ID de l'entreprise comme clé unique
                    lg={4}
                    md={6}
                    className="mb-4"
                    ref={index === 0 ? firstCardRef : null}
                  >
                    <CompanyCard company={item} />
                  </Col>
                ))}
              </Masonry>
              <Pagination
                currentPage={company.currentPage}
                totalPages={company.totalPages}
                fetchData={(page) => dispatch(fetchCompaniesPaged(page))}
                setCurrentPage={setCurrentPage}
                firstCardRef={firstCardRef}
              />
            </>
          )}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CompanyList;
