import React from "react";
import { Card } from "reactstrap";

//swiper css
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

const BlogSwiper = ({ selectedDetails }) => {
  const coverPhoto =
    selectedDetails?.coverPhoto ||
    "https://res.cloudinary.com/dryskp7hv/image/upload/v1725631755/13379599_5191132_xuuqgj.jpg";

  return (
    <React.Fragment>
      <Card className="card blog-modern-box overflow-hidden">
        <img src={coverPhoto} alt="" className="img-fluid" />
      </Card>
    </React.Fragment>
  );
};

export default BlogSwiper;
