import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import Section from "../../../pages/Blog/BlogGrid/Section";
import BlogText from "../../Blog/BlogGrid/BlogText";
import BlogCategory from "../../Blog/BlogGrid/BlogCategory";
import { useDispatch, useSelector } from "react-redux";
import { fetchTrainingDetails } from "../../../redux/training/action";
import { useParams } from "react-router-dom";
import Lottie from "react-lottie";
import * as animationData from "../../../assets/loading.json";
import './style.css'; // Importer le fichier CSS pour les styles

const BlogGrid = () => {
  document.title = "Cours | RecruTech";

  const { slug } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const { selectedDetails } = useSelector((state) => state.trainingReducer);

  const [activeModule, setActiveModule] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null); // Utiliser un seul état pour le cours sélectionné

  useEffect(() => {
    dispatch(fetchTrainingDetails(slug));
    window.scrollTo(0, 0);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Simuler un temps de chargement de 1 seconde

    return () => clearTimeout(timer); // Nettoyage du timer
  }, [dispatch, slug]);

  // Paramètres pour l'animation Lottie
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        {loading ? (
          <div className="text-center">
            <Lottie options={defaultOptions} height={400} width={400} />
          </div>
        ) : (
          <Container>
            <Row className="blog-grid">
              {/* Affichage des détails du cours dans la colonne droite */}
              <Col lg={8} md={8}>
                <div className="blog-post-container">
                  {selectedCourse ? (
                    <BlogText course={selectedCourse} />
                  ) : (
                    <p className="text-muted">
                      Sélectionnez un cours pour voir les détails.
                    </p>
                  )}
                </div>
              </Col>
              {/* Affichage des modules et cours via BlogCategory */}
              <Col lg={4} md={4}>
                <div className="sidebar-container">
                  <BlogCategory
                    modules={selectedDetails?.trainingModules}
                    onSelectCourse={setSelectedCourse} // Mettre à jour le cours sélectionné
                    activeModule={activeModule}
                    setActiveModule={setActiveModule}
                    selectedCourse={selectedCourse} // Passer le cours sélectionné
                  />
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    </React.Fragment>
  );
};

export default BlogGrid;
