import React, { useEffect, useState } from "react";
import { Col, Card, CardBody, Container, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { fetchSimilarJobsPaged } from "../../../redux/job/action";
import { fetchJobDetails } from "../../../redux/job/action";
import { useDispatch, useSelector } from "react-redux";
import { formatAddress } from "../../../utils";

import Masonry from "react-masonry-component";

const JobVacancyPost = ({ firstCardRef, selectedDetails }) => {
  const dispatch = useDispatch();
  const { job, loading } = useSelector((state) => state.jobReducer) || {}; // Assurez-vous que state.jobReducer est initialisé correctement
  const [showAlljob] = useState(false);

  const handleJobClick = (selectedDetails) => {
    dispatch(fetchJobDetails(selectedDetails));
  };

  const getBadgeClass = (contract) => {
    switch (contract) {
      case "CDI":
        return "badge bg-success-subtle text-success fs-10 me-2";
      case "CDD":
        return "badge bg-danger-subtle text-danger fs-10 me-2";
      case "Freelance":
        return "badge bg-info-subtle text-info fs-10 mt-1 me-2";
      case "Stage":
        return "badge bg-warning-subtle text-warning fs-10 me-2";
      default:
        return "badge bg-secondary-subtle text-secondary fs-10 me-2";
    }
  };

  useEffect(() => {
    if (selectedDetails && selectedDetails.id) {
      dispatch(fetchSimilarJobsPaged(selectedDetails.id)); // Assurez-vous que selectedDetails.id est défini correctement
      window.scrollTo(0, 0);
    }
  }, [dispatch, selectedDetails]);

  // Vérifie si job est défini et est un tableau avant d'appeler .slice(0, 2)
  const visiblejob = Array.isArray(job.content)
    ? showAlljob
      ? job.content
      : job.content.slice(0, 2)
    : [];

  return (
    <React.Fragment>
      <Container>
        {loading ? (
          <Spinner color="primary" />
        ) : (
          <Masonry className="row mt-3">
            {visiblejob.map((job) => (
              <Col
                key={job.id}
                lg={6}
                md={6}
                className="mb-4"
                ref={firstCardRef}
              >
                <Link
                  to={{ pathname: `/jobdetails/${job.id}` }}
                  className="primary-link"
                  onClick={() => handleJobClick(job)}
                >
                  <Card
                    className="blog-masonry-box shadow overflow-hidden border-0 p-2"
                    style={{ height: "100%" }}
                  >
                    {/* <div
                      className="overflow-hidden"
                      style={{ height: "170px" }}
                    >
                      <img
                        src={
                          job.company?.coverPhoto ||
                          "https://res.cloudinary.com/dryskp7hv/image/upload/v1701773538/Eburnie%20hub/img/modern-business-buildings_720_aka0ak.jpg"
                        }
                        alt=""
                        className="img-fluid blog-img"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div> */}
                    <CardBody className="p-4" style={{ height: "200px" }}>
                      <div className="d-flex align-items-center mt-1 mb-2">
                        <div className="flex-shrink-0">
                          <img
                            src={
                              job.company?.logo ||
                              "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                            }
                            alt=""
                            className="avatar-xs rounded-circle"
                          />
                        </div>
                        <div className="ms-3">
                          <h6 className="text-muted fs-14 mb-0">
                            {job.company?.name}
                          </h6>
                        </div>
                      </div>
                      <h6>{job.title}</h6>
                      <div className="row d-flex align-items-start mt-3">
                        <div className="col-6">
                          <div className="ms-0">
                            {job.contractType.map((contract) => (
                              <span
                                key={contract}
                                className={getBadgeClass(contract)}
                              >
                                {contract}
                              </span>
                            ))}
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="ms-3">
                            <p className="text-muted">
                              <i className="mdi mdi-map-marker"></i>{" "}
                              {formatAddress(job.address)}
                            </p>
                          </div>
                        </div>
                      </div>
                      <p className="text-muted">
                        <i className="mdi mdi-calendar"></i>{" "}
                        {job.durationSincePosting}
                      </p>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            ))}
          </Masonry>
        )}
      </Container>
      {!showAlljob && job.content.length > 2 && (
        <div className="text-center mt-4">
          <Link
            to={`/jobs?companyId=${selectedDetails.company.id}`}
            className="primary-link form-text"
          >
            Voir plus <i className="mdi mdi-arrow-right"></i>
          </Link>
        </div>
      )}
    </React.Fragment>
  );
};

export default JobVacancyPost;
