import TrainingService from '../../../service/training';
import * as TrainingActionTypes from '../types';

export const fetchAllTrainingsPaged = (page) => {
  return async (dispatch) => {
    dispatch({ type: TrainingActionTypes.FETCH_TRAININGS_REQUEST });

    try {
      const response = await TrainingService.fetchAllTrainingsPaged();
      dispatch({ type: TrainingActionTypes.FETCH_TRAININGS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: TrainingActionTypes.FETCH_TRAININGS_FAILURE, payload: error.message });
    }
  };
};

export const fetchTrainingDetails = (slug) => {
  return async (dispatch) => {
    dispatch({ type: TrainingActionTypes.SELECT_TRAINING_REQUEST });
    try {
      const response = await TrainingService.fetchTrainingDetails(slug);
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({ type: TrainingActionTypes.SELECT_TRAINING_SUCCESS, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: TrainingActionTypes.SELECT_TRAINING_FAILURE, payload: error.message });
    }
  };
};