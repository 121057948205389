import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "reactstrap";
import Section from "../BlogDetails/Section";
import { useDispatch, useSelector } from "react-redux";
import { fetchTrainingDetails } from "../../../redux/training/action";
import { useParams } from "react-router-dom";
import Lottie from "react-lottie";

import * as animationData from "../../../assets/loading.json"; // Remplacez par le chemin de votre fichier Lottie

import TextWidget from "../BlogGrid/TextWidget";
import Archives from "../BlogGrid/Archives";
import Tags from "../BlogGrid/Tags";
import BlogSwiper from "../BlogDetails/BlogSwiper";
import BlogColumn from "../BlogDetails/BlogColumn";

const BlogDetails = () => {
  document.title = "Formation Details | RecruTech";
  const { slug } = useParams();
  const dispatch = useDispatch();
  // Gérer l'état de chargement
  const [loading, setLoading] = useState(true);

  const { selectedDetails } = useSelector((state) => state.trainingReducer);

  useEffect(() => {
    // Utilisez la valeur de la page actuelle ici
    dispatch(fetchTrainingDetails(slug));
    window.scrollTo(0, 0);
    // Simuler un temps de chargement
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Temps de chargement simulé de 3 secondes

    return () => clearTimeout(timer); // Nettoyage du timer
  }, [dispatch, slug]);

  // Paramètres pour l'animation Lottie
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <React.Fragment>
      <Section selectedDetails={selectedDetails} />
      <section className="section">
        {loading ? (
          <div className="text-center">
            <Lottie options={defaultOptions} height={400} width={400} />
          </div>
        ) : (
          <Container>
            <Row>
              <Col lg={8}>
                <div className="blog-post">
                  <BlogSwiper selectedDetails={selectedDetails} />
                  <BlogColumn selectedDetails={selectedDetails} />
                  <Archives selectedDetails={selectedDetails} />
                  <TextWidget selectedDetails={selectedDetails} />
                </div>
              </Col>
              <Col lg={4}>
                <div className="sidebar ms-lg-4 ps-lg-4 mt-5 mt-lg-0">
                  <Tags selectedDetails={selectedDetails} />
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </section>
    </React.Fragment>
  );
};

export default BlogDetails;
