import axios from 'axios';

const baseURL = "http://recrutech.51.222.155.225.nip.io/";
// const baseURL = "http://localhost:8080/";

const instance = axios.create({
    baseURL,
    withCredentials: false,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Content-Type': 'application/json'
    }
});
export default instance;
