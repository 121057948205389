export const FETCH_TRAININGS_REQUEST = 'FETCH_TRAININGS_REQUEST';
export const FETCH_TRAININGS_SUCCESS = 'FETCH_TRAININGS_SUCCESS';
export const FETCH_TRAININGS_FAILURE = 'FETCH_TRAININGS_FAILURE';

export const FETCH_RECENT_TRAININGS_REQUEST = 'FETCH_RECENT_TRAININGS_REQUEST';
export const FETCH_RECENT_TRAININGS_SUCCESS = 'FETCH_RECENT_TRAININGS_SUCCESS';
export const FETCH_RECENT_TRAININGS_FAILURE = 'FETCH_RECENT_TRAININGS_FAILURE';

export const SELECT_TRAINING_REQUEST = 'SELECT_TRAINING_REQUEST';
export const SELECT_TRAINING_SUCCESS = 'SELECT_TRAINING_SUCCESS';
export const SELECT_TRAINING_FAILURE = 'SELECT_TRAINING_FAILURE';