import * as TrainingActionTypes from "../types";

const initialState = {
  allTrainings: {
    content: [],
    totalPages: undefined,
    currentPage: 0,
    totalElements: undefined,
  },
  loading: false,
  error: undefined,
  recentTrainings: [],
  selectedDetails: undefined,
};

const trainingReducer = (state = initialState, action) => {
  switch (action.type) {
    case TrainingActionTypes.FETCH_TRAININGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TrainingActionTypes.FETCH_TRAININGS_SUCCESS:
      return {
        ...state,
        loading: false,
        allTrainings: {
          ...action.payload.data, // Ici, l'objet complet est directement assigné à `allTrainings`
        },
        error: null,
      };
    case TrainingActionTypes.FETCH_TRAININGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TrainingActionTypes.FETCH_RECENT_TRAININGS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TrainingActionTypes.FETCH_RECENT_TRAININGS_SUCCESS:
      return {
        ...state,
        loading: false,
        recentTrainings: action.payload.data,
        error: null,
      };
    case TrainingActionTypes.FETCH_RECENT_TRAININGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case TrainingActionTypes.SELECT_TRAINING_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        selectedDetails: undefined, // Reset selectedDetails on new request
      };
    case TrainingActionTypes.SELECT_TRAINING_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedDetails: action.payload.data,
        error: null,
      };
    case TrainingActionTypes.SELECT_TRAINING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default trainingReducer;
