import React, { useState } from "react";
import { Button, Collapse, Row } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Assurez-vous d'importer Bootstrap CSS

const Archives = ({ selectedDetails }) => {
  const [activeModule, setActiveModule] = useState(null);

  return (
    <React.Fragment>
      <div className="mt-4 pt-2">
        <div className="sd-title">
          <h6 className="fs-16 mb-3">Contenu de la formation</h6>
        </div>
        <div className="side-bar mt-5 mb-3 mt-lg-0">
          <div className="accordion" id="accordionExample">
            {selectedDetails.trainingModules.map((module) => (
              <div className="accordion-item" key={module.id}>
                <h2 className="accordion-header" id={`heading${module.id}`}>
                  <Button
                    className="accordion-button"
                    onClick={() =>
                      setActiveModule(
                        activeModule === module.id ? null : module.id
                      )
                    }
                    role="button"
                    aria-expanded={activeModule === module.id}
                  >
                    {module.title}
                  </Button>
                </h2>
                <Collapse isOpen={activeModule === module.id}>
                  <div className="accordion-body">
                    {/* Boucle pour afficher les cours */}
                    {module.courses.map((course) => (
                      <Row key={course.id} className="mb-1">
                        <p className="text-muted fs-14">
                          <i className="uil uil-check"></i>
                          <span
                            dangerouslySetInnerHTML={{ __html: course.title }}
                          />
                        </p>
                      </Row>
                    ))}
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Archives;
