import { combineReducers } from "redux";

import auth from './auth/reducer'
import message from './message/reducer'
import companyReducer from './company/reducer';
import jobReducer from './job/reducer';
import trainingReducer from './training/reducer';
import jobCategoryReducer from './jobCategory/reducer';

const rootReducer = combineReducers({
    auth,
    message,
    companyReducer,
    jobReducer,
    trainingReducer,
    jobCategoryReducer
});
export default rootReducer;
