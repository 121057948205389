import React from "react";

const TextWidget = ({ selectedDetails }) => {
  return (
    <React.Fragment>
      <div className="mt-4 pt-2">
        <div className="sd-title">
          <h6 className="fs-16 mb-3">Description</h6>
        </div>
        {/* Utilisation de dangerouslySetInnerHTML pour afficher le contenu HTML */}
        <div 
          className="mb-0 text-muted mt-3" 
          dangerouslySetInnerHTML={{ __html: selectedDetails.description }} 
        />
      </div>
    </React.Fragment>
  );
};

export default TextWidget;
