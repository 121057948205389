import * as AuthActionTypes from "../types";
import authService from "../../../service/auth";
import TokenService from "../../../service/TokenService";
import { clearMessage, setMessage } from "../../message/action";
import { toast } from "react-toastify"; // Ajoutez cette ligne

export const clearRegister = () => async (dispatch) => {
  dispatch({ type: AuthActionTypes.CLEAR_REGISTER });
};

export const signInAction =
  ({ username, password }) =>
  async (dispatch) => {
    dispatch({ type: AuthActionTypes.SIGNIN_USER_REQUEST });
    try {
      const response = await authService.signIn(username, false, password);
      if (response.status === 200 && response.data) {
        const { data: currentUser } = await authService.getCurrentUser(
          response.data.data.id_token
        );

        // Vérification du rôle de l'utilisateur
        const userRoles = currentUser.authorities || [];
        if (!userRoles.includes("ROLE_USER")) {
          dispatch({ type: AuthActionTypes.SIGNIN_USER_ERROR });
          dispatch(
            setMessage(
              "Accès refusé : vous devez être un candidat pour vous connecter."
            )
          );
          return { success: false }; // Modification ici
        }
        const payload = { ...response.data, ...currentUser };
        TokenService.setUser(payload);
        dispatch({ type: AuthActionTypes.SIGNIN_USER_SUCCESS, payload });
        return { success: true }; // Modification ici
      } else {
        dispatch({ type: AuthActionTypes.SIGNIN_USER_ERROR });
        dispatch(setMessage("Adresse e-mail ou mot de passe incorrect"));
        return { success: false }; // Modification ici
      }
    } catch (error) {
      let message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      const errorCode = error?.response?.data?.errorCode;

      dispatch({ type: AuthActionTypes.SIGNIN_USER_ERROR, payload: errorCode });

      if (errorCode === "USER_NOT_ACTIVATED") {
        dispatch(resendOtp(username, password));
      } else {
        if (errorCode === "BadCredentialsException") {
          message = "Adresse e-mail ou mot de passe incorrect";
        }
        dispatch(setMessage(message));
      }
    }
  };

export const signUpAction =
  ({ email, password, firstName, lastName }) =>
  async (dispatch) => {
    dispatch({ type: AuthActionTypes.SIGNUP_USER_REQUEST });
    dispatch(clearMessage());
    try {
      const payload = {
        login: email, // Utilisez l'email comme login
        firstName: firstName,
        lastName: lastName,
        email: email,
        langKey: "fr", // Langue par défaut définie sur "fr"
        authorities: ["ROLE_USER"], // Autorité par défaut pour le candidat
        password: password,
      };

      const { data } = await authService.signUp(payload);
      if (data.statusCode === 200) {
        dispatch({
          type: AuthActionTypes.SIGNUP_USER_SUCCESS,
          payload: { email, password },
        });
        dispatch(setMessage("Votre compte a été crée avec succès"));
      } else {
        dispatch({ type: AuthActionTypes.SIGNUP_USER_ERROR });
        dispatch(setMessage("Erreur lors de la création de votre compte"));
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: AuthActionTypes.SIGNUP_USER_ERROR });
      dispatch(setMessage(message));
    }
  };

export const activateAccount = (otpNumber) => async (dispatch) => {
  const email = localStorage.getItem("email");
  const password = localStorage.getItem("password");
  dispatch({ type: AuthActionTypes.OTP_USER_REQUEST });
  dispatch(clearMessage());

  try {
    const { data } = await authService.activateAccount(email, otpNumber);

    if (data.statusCode === 200) {
      dispatch({ type: AuthActionTypes.OTP_USER_SUCCESS });
      toast.success("Vérification effectuée avec succès");

      // Connexion automatique
      const loginResponse = await dispatch(
        signInAction({ username: email, password })
      );

      if (loginResponse && loginResponse.success) {
        // Nettoyage
        localStorage.removeItem("email");
        localStorage.removeItem("password");

        return { success: true }; // Indiquer le succès après la réussite de la connexion
      } else {
        // Si la connexion échoue
        toast.error("Connexion automatique échouée");
        return { success: false }; // Échec de la connexion
      }
    } else {
      dispatch({ type: AuthActionTypes.OTP_USER_ERROR });
      toast.error("Le code de vérification est incorrect");
      return { success: false }; // Indiquer l'échec
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    dispatch({ type: AuthActionTypes.OTP_USER_ERROR });
    dispatch(setMessage(message));
    toast.error(message);
    return { success: false }; // Indiquer l'échec
  }
};

export const resendOtp = () => async (dispatch) => {
  const email = localStorage.getItem("email");
  dispatch({ type: AuthActionTypes.OTP_RESEND_REQUEST });
  dispatch(clearMessage());

  try {
    const response = await authService.resendOtp(email);
    // Vérifiez si response et response.data existent
    if (response && response.data) {
      if (response.data.statusCode === 200) {
        dispatch({ type: AuthActionTypes.OTP_RESEND_SUCCESS });
        toast.success("Code renvoyé! Veuillez vérifier");
      } else {
        dispatch({ type: AuthActionTypes.OTP_RESEND_ERROR });
        toast.error("Erreur lors du renvoi du code de vérification");
      }
    } else {
      // Gestion des cas où response.data est undefined
      dispatch({ type: AuthActionTypes.OTP_RESEND_ERROR });
      toast.error("Erreur de réponse du serveur");
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    dispatch({
      type: AuthActionTypes.OTP_USER_ERROR,
      payload: error.response ? error.response.data.errorCode : "UNKNOWN_ERROR",
    });
    dispatch(setMessage(message));
  }
};

export const resetPasswordInit = (email) => async (dispatch) => {
  dispatch({ type: AuthActionTypes.OTP_USER_REQUEST });
  dispatch(clearMessage());

  try {
    const { data } = await authService.resetPasswordInit(email);

    if (data.statusCode === 200) {
      dispatch({ type: AuthActionTypes.OTP_USER_SUCCESS });
      toast.success("Vérification effectuée avec succès");

      return { success: true }; // Indiquer le succès
    } else {
      dispatch({ type: AuthActionTypes.OTP_USER_ERROR });
      toast.error("L'email' est incorrecte Veuillez vérifier");
      return { success: false }; // Indiquer l'échec
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    dispatch({ type: AuthActionTypes.OTP_USER_ERROR });
    toast.error(message);
    return { success: false }; // Indiquer l'échec
  }
};

export const resetPasswordFinish =
  (otpNumber, newPassword) => async (dispatch) => {
    dispatch({ type: AuthActionTypes.OTP_USER_REQUEST });
    dispatch(clearMessage());

    try {
      const { data } = await authService.resetPasswordFinish(
        otpNumber,
        newPassword
      );

      if (data.statusCode === 200) {
        dispatch({ type: AuthActionTypes.OTP_USER_SUCCESS });
        toast.success("Mot de passe mise à jour avec succès");

        return { success: true }; // Indiquer le succès
      } else {
        dispatch({ type: AuthActionTypes.OTP_USER_ERROR });
        toast.error("Code invalide Veuillez vérifier");
        return { success: false }; // Indiquer l'échec
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({ type: AuthActionTypes.OTP_USER_ERROR });
      toast.error(message);
      return { success: false }; // Indiquer l'échec
    }
  };

export const signOutAction = () => async (dispatch) => {
  dispatch({ type: AuthActionTypes.SIGNOUT_USER_REQUEST });
  try {
    authService.signOut();
    dispatch({ type: AuthActionTypes.SIGNOUT_USER_SUCCESS });
  } catch (err) {
    dispatch({ type: AuthActionTypes.SIGNOUT_USER_ERROR, err });
  }
};

export const clearError = () => (dispatch) => {
  dispatch({ type: AuthActionTypes.CLEAR_ERROR });
};

const AuthService = {
  signUpAction,
  signInAction,
  signOutAction,
};
export default AuthService;
