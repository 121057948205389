import React from 'react'
import { Link } from 'react-router-dom';
import {Col, Container, Row} from "reactstrap";

const Section = () => {
    return (
        <React.Fragment>
             <section className="page-title-box">
                        <Container>
                            <Row className="justify-content-center">
                                <Col md={6}>
                                    <div className="text-center text-white">
                                        <h3 className="mb-4">DEVELOPPEMENT JAVA (SPRING BOOT)</h3>
                                        <div className="page-next">
                                            <nav className="d-inline-block" aria-label="breadcrumb text-center">
                                                <ol className="breadcrumb justify-content-center">
                                                    <li className="breadcrumb-item"><Link to="/">Acceuil</Link></li>
                                                    <li className="breadcrumb-item"><Link to="#">Formation</Link></li>
                                                    <li className="breadcrumb-item active" aria-current="page"> Cours </li>
                                                </ol>
                                            </nav>
                                        </div>
                                    </div>
                                </Col>                               
                            </Row>                           
                        </Container>                       
                    </section>
        </React.Fragment>
    )
}

export default Section;
