import React, { useEffect, useRef } from "react";
import { Container, Spinner } from "reactstrap";
import Masonry from "react-masonry-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchJobsPaged } from "../../../redux/job/action";
import Pagination from "../../CandidateAndCompany/CompanyList/Pagination";
import JobCardItem from "./JobCardItem";
import { useLocation } from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const JobVacancy = () => {
  const firstCardRef = useRef(null);
  const dispatch = useDispatch();
  const { job, loading } = useSelector((state) => state.jobReducer);
  const query = useQuery();
  const companyId = query.get("companyId") || ""; // Récupérer l'ID de l'entreprise ou une chaîne vide

  const setCurrentPage = (page) => {
    dispatch(fetchJobsPaged(page, companyId)); // Passer l'ID de l'entreprise
  };

  useEffect(() => {
    dispatch(fetchJobsPaged(job.currentPage, companyId)); // Passer l'ID de l'entreprise
    window.scrollTo(0, 0);
  }, [dispatch, job.currentPage, companyId]); // Ajouter companyId comme dépendance

  return (
    <React.Fragment>
      <Container>
        {loading ? (
          <div className="text-center mt-5">
            <Spinner
              color="primary"
              style={{ width: "3rem", height: "3rem" }}
            />
          </div>
        ) : (
          <Masonry className="row">
            {job.content.map((item, index) => (
              <JobCardItem
                key={item.id}
                job={item}
                index={index}
                firstCardRef={index === 0 ? firstCardRef : null}
              />
            ))}
          </Masonry>
        )}
        <Pagination
          currentPage={job.currentPage}
          totalPages={job.totalPages}
          fetchData={fetchJobsPaged}
          setCurrentPage={setCurrentPage}
          firstCardRef={firstCardRef}
          companyId={companyId} // Passer companyId à Pagination
        />
      </Container>
    </React.Fragment>
  );
};

export default JobVacancy;
