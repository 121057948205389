import React, { useEffect, useRef } from "react";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTrainingsPaged } from "../../../redux/training/action";
import Masonry from "react-masonry-component";
import Section from "../../Blog/BlogMasonary/Section";
import MasonaryContent from "../../Blog/BlogMasonary/MasonaryContent";
import Lottie from "react-lottie";
import * as loadingAnimationData from "../../../../src/assets/loading.json";
import * as errorAnimationData from "../../../../src/assets/error.json"; // Animation pour l'erreur
import Pagination from "../../CandidateAndCompany/CompanyList/Pagination";

const BlogMasonary = () => {
  document.title = "Nos Formations | RecruTech";
  const firstCardRef = useRef(null);
  const dispatch = useDispatch();
  const { loading, allTrainings, error } = useSelector(
    (state) => state.trainingReducer
  );

  const setCurrentPage = (page) => {
    dispatch(fetchAllTrainingsPaged(page)); // Passer l'ID de l'entreprise
  };
  useEffect(() => {
    dispatch(fetchAllTrainingsPaged());
    window.scrollTo(0, 0);
  }, [dispatch]);

  // Paramètres pour l'animation Lottie de chargement
  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Paramètres pour l'animation Lottie d'erreur
  const errorOptions = {
    loop: true,
    autoplay: true,
    animationData: errorAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <React.Fragment>
      <Section />
      <section className="mt-4">
        <Container>
          {loading ? (
            <div className="text-center mt-5">
              <Lottie options={loadingOptions} height={400} width={400} />
            </div>
          ) : error ? (
            <div className="text-center mt-5">
              <Lottie options={errorOptions} height={400} width={400} />
              <p className="text-danger mt-3">
                Une erreur s'est produite : {error}
              </p>
            </div>
          ) : (
            <>
              <Masonry className="row mb-3">
                {allTrainings.content &&
                  allTrainings.content
                    .slice(0, 6)
                    .map((item, index) => (
                      <MasonaryContent
                        key={item.id}
                        training={item}
                        index={index}
                        firstCardRef={index === 0 ? firstCardRef : null}
                      />
                    ))}
              </Masonry>
              <Pagination
                currentPage={allTrainings.currentPage}
                totalPages={allTrainings.totalPages}
                fetchData={fetchAllTrainingsPaged}
                setCurrentPage={setCurrentPage}
                firstCardRef={firstCardRef}
              />
            </>
          )}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default BlogMasonary;
