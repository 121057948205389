import JobService from '../../../service/job';
import * as JobActionTypes from '../types';

export const fetchJobsPaged = (page, companyId) => {
  return async (dispatch) => {
    dispatch({ type: JobActionTypes.FETCH_JOBS_REQUEST });

    try {
      const response = await JobService.fetchJobsPaged(page, companyId);
      dispatch({ type: JobActionTypes.FETCH_JOBS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: JobActionTypes.FETCH_JOBS_FAILURE, payload: error.message });
    }
  };
};

export const fetchSimilarJobsPaged = (jobId) => {
  return async (dispatch) => {
    dispatch({ type: JobActionTypes.FETCH_JOBS_REQUEST });

    try {
      const response = await JobService.fetchSimilarJobsPaged(jobId);
      dispatch({ type: JobActionTypes.FETCH_JOBS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: JobActionTypes.FETCH_JOBS_FAILURE, payload: error.message });
    }
  };
};

export const fetchRecentJobs = () => {
  return async (dispatch) => {
    dispatch({ type: JobActionTypes.FETCH_RECENT_JOBS_REQUEST });
    try {
      const response = await JobService.fetchRecentJobs();
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({ type: JobActionTypes.FETCH_RECENT_JOBS_SUCCESS, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: JobActionTypes.FETCH_RECENT_JOBS_FAILURE, payload: error.message });
    }
  };
};

export const fetchJobDetails = (jobId) => {
  return async (dispatch) => {
    dispatch({ type: JobActionTypes.SELECT_JOB_REQUEST });
    try {
      const response = await JobService.fetchJobdetails(jobId);
      console.log("response", response);
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({ type: JobActionTypes.SELECT_JOB_SUCCESS, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: JobActionTypes.SELECT_JOB_FAILURE, payload: error.message });
    }
  };
};

export const fetchCompanyDetails = (companyId) => {
  return async (dispatch) => {
    dispatch({ type: JobActionTypes.SELECT_JOB_REQUEST });
    try {
      const response = await JobService.fetchCompanyDetails(companyId);
      console.log("response", response);
      if (response && response.data && response.data.statusCode === 200) {
        dispatch({ type: JobActionTypes.SELECT_JOB_SUCCESS, payload: response.data });
      }
    } catch (error) {
      dispatch({ type: JobActionTypes.SELECT_JOB_FAILURE, payload: error.message });
    }
  };
};

// export const selectJob = job => {
//   return {
//     type: JobActionTypes.SELECT_JOB,
//     payload: job,
//   };
// };