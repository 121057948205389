import api from "../Api";

const fetchAllTrainingsPaged = async (page = 0) => {
  const url = `/api/public/candidate/trainings?size=6&page=${page}`;
  return await api.get(url);
};

const fetchTrainingDetails = async (slug) => {
  const url = `/api/public/candidate/training/${slug}`;
  return await api.get(url);
};


const JobService = {
fetchAllTrainingsPaged,
fetchTrainingDetails
};

export default JobService;
