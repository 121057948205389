import Api from "../Api";
import TokenService from "../TokenService";
import authHeader from "./auth-header";

// authService.js

const signIn = async (username, rememberMe, password) => {
  return await Api.post("/api/authenticate", {
    username,
    rememberMe,
    password,
  });
};

const signUp = async (payload) => {
  return await Api.post("/api/public/candidate/register", payload);
};

const activateAccount = async (email, otpNumber) => {
  return await Api.post("/api/account/activate", {
    key: email,
    otpNumber: otpNumber,
  });
};

const resetPasswordInit = async (email) => {
  return await Api.post("/api/account/reset-password/init", { email: email });
};

const resetPasswordFinish = async (otpNumber, newPassword) => {
  return await Api.post("/api/account/reset-password/finish", {
    key: otpNumber,
    newPassword: newPassword,
  });
};

const resendOtp = async (email) => {
  return await Api.get("/api/resend-otp", { params: { email } });
};

const getCurrentUser = async (token) => {
  const { data: response } = await Api.get("api/account", {
    headers: authHeader(token),
  });
  return response;
};

const signOut = () => {
  TokenService.removeUser();
};

const AuthService = {
  signIn,
  signOut,
  signUp,
  getCurrentUser,
  activateAccount,
  resendOtp,
  resetPasswordInit,
  resetPasswordFinish,
};

export default AuthService;
