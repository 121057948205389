import React, { useEffect } from "react";
import { Container, Row, Spinner, Col } from "reactstrap";
import LeftSideContent from "./LeftSideContent";
import { fetchCompanyDetails } from "../../../redux/job/action";
import RightSideContent from "./RightSideContent";
import Section from "./Section";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const CompanyDetails = () => {
  const dispatch = useDispatch();
  const { companyId } = useParams();
  document.title = "Company Details | RecruTech";
  const { selectedDetails } = useSelector((state) => state.jobReducer);
  useEffect(() => {
    // Utilisez la valeur de la page actuelle ici
    dispatch(fetchCompanyDetails(companyId));
    window.scrollTo(0, 0);
  }, [dispatch, companyId]);

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            {selectedDetails ? (
              <>
                <LeftSideContent selectedDetails={selectedDetails} />
                <RightSideContent selectedDetails={selectedDetails} />
              </>
            ) : (
              <Col lg={12}>
                <div className="text-center mt-5">
                  <Spinner
                    color="primary"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                </div>
                <div className="text-center mt-5">
                  <p>Chargement des détails de l'entreprise...</p>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CompanyDetails;
