import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, Card, CardBody, Input, Label } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const RightSideContent = ({ selectedDetails }) => {
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(true);
  const closeModal = () => setModal(false);

  const { authUser } = useSelector(({ auth }) => auth);

  const onSubmit = () => {
    if (authUser && authUser.isLoggedIn) {
      toast.success('Candidature reçue !', {
        position: toast.POSITION.TOP_CENTER,
      });
      closeModal();
    } else {
      toast.error('Connectez-vous pour continuer', {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="side-bar ms-lg-4">
        <Card className="job-overview">
          <CardBody className="p-4">
            <h6 className="fs-17">Aperçu du poste</h6>
            <ul className="list-unstyled mt-4 mb-0">
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-user icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Titre du poste</h6>
                    <p className="text-muted mb-0">{selectedDetails?.title}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-star-half-alt icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Experience</h6>
                    <p className="text-muted mb-0">{selectedDetails?.experience} Ans</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-location-point icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Lieu</h6>
                    <p className="text-muted mb-0">
                      {selectedDetails?.address?.city}, {selectedDetails?.address?.country}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-usd-circle icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Salaire proposé</h6>
                    <p className="text-muted mb-0">
                      {`${selectedDetails?.minSalary} ${selectedDetails?.currency} - ${selectedDetails?.maxSalary} ${selectedDetails?.currency}`}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-building icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Industrie</h6>
                    <p className="text-muted mb-0">{selectedDetails?.category}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-history icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Date de publication</h6>
                    <p className="text-muted mb-0">{selectedDetails?.durationSincePosting}</p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="mt-3">
              <Link to="#applyNow" onClick={openModal} className="btn btn-primary btn-hover w-100 mt-2">
                Candidater <i className="uil uil-arrow-right"></i>
              </Link>
            </div>
          </CardBody>
        </Card>

        <Card className="company-profile mt-4">
          <CardBody className="p-4">
            <div className="text-center">
              <img
                src={selectedDetails?.company?.coverPhoto || 'https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg'}
                width="100" height="100" alt="" className="img-fluid rounded-3" />

              <div className="mt-4">
                <h6 className="fs-17 mb-1">{selectedDetails?.company?.name}</h6>
                <p className="text-muted">Depuis juillet 2017</p>
              </div>
            </div>
            <ul className="list-unstyled mt-4">
              <li>
                <div className="d-flex">
                  <i className="uil uil-phone-volume text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Phone</h6>
                    <p className="text-muted fs-14 mb-0">{selectedDetails?.company?.telephone}</p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="uil uil-envelope text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Email</h6>
                    <p className="text-muted fs-14 mb-0">{selectedDetails?.company?.email}</p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="uil uil-globe text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Website</h6>
                    <p className="text-muted fs-14 text-break mb-0">{selectedDetails?.company?.website}</p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="uil uil-map-marker text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Localisation</h6>
                    <p className="text-muted fs-14 mb-0">
                      {`${selectedDetails?.company?.address?.city}, ${selectedDetails?.company?.address?.country}`}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="mt-4">
              <Link to={{ pathname: `/companydetails/${selectedDetails?.company?.id}` }} className="btn btn-primary btn-hover w-100 rounded">
                <i className="mdi mdi-eye"></i> Voir le Profile
              </Link>
            </div>
          </CardBody>
        </Card>

        <div className="mt-4">
          <h6 className="fs-16 mb-3">Lieu de travail</h6>
          <iframe
            title="maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.15830869428!2d-74.119763973046!3d40.69766374874431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1628067715234!5m2!1sen!2sin"
            style={{ width: `100%`, height: `250px` }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className="modal fade" id="applyNow" tabIndex="-1" aria-labelledby="applyNow" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <Modal isOpen={modal} toggle={openModal} centered>
              <ModalBody className="modal-body p-5">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Commencer sa Candidature
                  </h5>
                  <p className="text-muted mb-0 fs-15">{selectedDetails?.title}</p>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button type="button" onClick={closeModal} className="btn-close" aria-label="Close"></button>
                </div>
                <div className="mb-3">
                  <Label for="messageControlTextarea" className="form-label">
                    Votre message au recruteur (Optionnel)
                  </Label>
                  <textarea className="form-control" id="messageControlTextarea" rows="4" placeholder="Entrer un message"></textarea>
                </div>
                <div className="mb-4">
                  <Label className="form-label" for="inputGroupFile01">
                    Mon CV
                  </Label>
                  <Input type="file" className="form-control" id="inputGroupFile01" />
                </div>
                <button type="button" onClick={onSubmit} className="btn btn-primary w-100">
                  Candidater
                </button>
                <ToastContainer />
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RightSideContent;
