import React, { useEffect } from "react";
import { Col, Card, CardBody, Row, Spinner } from "reactstrap";
import { fetchJobsPaged } from "../../../redux/job/action";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatAddress } from "../../../utils";
import { fetchJobDetails } from "../../../redux/job/action";

const RightSideContent = ({ selectedDetails }) => {
  const dispatch = useDispatch();
  const { job, loading } = useSelector((state) => state.jobReducer);

  const handleJobClick = (selectedDetails) => {
    dispatch(fetchJobDetails(selectedDetails));
  };

  const getBadgeClass = (contract) => {
    switch (contract) {
      case "CDI":
        return "badge bg-success-subtle text-success fs-10 me-2";
      case "CDD":
        return "badge bg-danger-subtle text-danger fs-10 me-2";
      case "Freelance":
        return "badge bg-info-subtle text-info fs-10 mt-1 me-2";
      case "Stage":
        return "badge bg-warning-subtle text-warning fs-10 me-2";
      default:
        return "badge bg-secondary-subtle text-secondary fs-10 me-2";
    }
  };

  const popular = [
    {
      id: 1,
      count: "20.5 M",
      jobTitle: " Chiffre d'affaire",
    },
    {
      id: 2,
      count: "500",
      jobTitle: "collaborateurs",
    },
    {
      id: 3,
      count: "5",
      jobTitle: "Pays",
    },
    {
      id: 4,
      count: "15",
      jobTitle: "an(s) d'expérience(s)",
    },
  ];

  useEffect(() => {
    dispatch(fetchJobsPaged(job.currentPage, selectedDetails.id)); // Mettre à jour avec job.currentPage si nécessaire
    window.scrollTo(0, 0);
  }, [dispatch, job.currentPage, selectedDetails.id]);

  return (
    <React.Fragment>
      <Col lg={8}>
        <Card className="ms-lg-4 mt-4 mt-lg-0">
          <CardBody className="p-4">
            <div className="mb-5">
              <h6 className="fs-17 fw-semibold mb-4">A propos de nous</h6>
              <p className="text-muted">{selectedDetails.description}</p>
            </div>
            <div className="mb-5">
              <h6 className="fs-17 fw-semibold mb-4">
                Pourquoi nous rejoindre
              </h6>
              <ul className="job-detail-list list-unstyled mb-0 text-muted">
                <li>
                  <i className="uil uil-plus"></i> 13ème mois
                </li>
                <li>
                  <i className="uil uil-plus"></i> Flexibilité des horaires
                </li>
                <li>
                  <i className="uil uil-plus"></i> Activités sportives
                </li>
                <li>
                  <i className="uil uil-plus"></i> Bonne mutuelle
                </li>
              </ul>            
              </div>
            <div className="mb-5">
              <h6 className="fs-17 fw-semibold mb-4">
                Notre processus de recrutement
              </h6>
              <ul className="job-detail-list list-unstyled mb-0 text-muted">
                <li>
                  <i className="uil uil-circle"></i> Pré-sélection par le
                  recruteur en charge du poste
                </li>
                <li>
                  <i className="uil uil-circle"></i> Qualification téléphonique
                  pour la vérification du profil
                </li>
                <li>
                  <i className="uil uil-circle"></i> Entretien de recrutement
                  avec le recruteur
                </li>
                <li>
                  <i className="uil uil-circle"></i> Entretien de recrutement
                  avec le manager et/ou des opérationnels
                </li>
              </ul>
            </div>
            <div className="mb-5">
              <h6 className="fs-17 fw-semibold mb-4">
                L'entreprise en chiffre
              </h6>
              <div className="wedget-popular-title mt-1">
                {/* <h5>Populaire</h5> */}
                <ul className="list-inline">
                  {(popular || []).map((popularDetails, key) => (
                    <li className="list-inline-item" key={key}>
                      <div className="popular-box d-flex align-items-center">
                        <div className="lex-shrink-0 me-2">
                          {popularDetails.count}
                        </div>
                        <br />
                        <Link to="#" className="warning-link stretched-link">
                          <h6 className="fs-14 mb-0">
                            {popularDetails.jobTitle}
                          </h6>
                        </Link>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <h6 className="fs-17 fw-semibold mb-4">Ouverture actuelle</h6>
              {loading ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : (
                job.content &&
                job.content.slice(0, 2).map((jobDetails, index) => (
                  <Col md={12}>
                    <div className="job-box bookmark-post card mt-4">
                      <div className="bookmark-label text-center">
                        <Link to="#" className="align-middle text-white">
                          <i className="mdi mdi-star"></i>
                        </Link>
                      </div>
                      <div className="p-4">
                        <Row className="align-items-center mb-1">
                          <Col md={8}>
                            <div className="text-start">
                              <h5 className="fs-18 mb-1">
                                <Link
                                  to={{
                                    pathname: `/jobdetails/${jobDetails.id}`,
                                  }}
                                  className="primary-link"
                                  onClick={() => handleJobClick(jobDetails)}
                                >
                                  {jobDetails.title}
                                </Link>
                              </h5>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="text-end mb-4">
                              <Link
                                to={{
                                  pathname: `/companydetails/${jobDetails.company.id}`,
                                }}
                              >
                                <img
                                  src={
                                    jobDetails.company.logo ||
                                    "https://res.cloudinary.com/dryskp7hv/image/upload/v1701774315/Eburnie%20hub/logo/logo1_480_tnbivi.jpg"
                                  }
                                  alt=""
                                  className="img-fluid rounded-3"
                                  width={50}
                                  height={50}
                                />
                              </Link>
                            </div>
                          </Col>
                        </Row>
                        <Row className=" text-align-justify mb-0">
                          <Col md={7}>
                            <div
                              style={{ textAlign: "justify" }}
                              className="text-start mb-0 mb-md-0"
                            >
                              <p className="text-muted fs-15 mb-0">
                                {jobDetails.description}
                              </p>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className=" text-end mb-0 mb-md-0">
                              <p className="text-muted fs-14 mb-0">
                                {jobDetails.company.name}
                              </p>
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-center">
                          <div className="text-end">
                            <div className="ms-0">
                              {jobDetails.contractType.map((contract) => (
                                <span
                                  key={contract}
                                  className={getBadgeClass(contract)}
                                >
                                  {contract}
                                </span>
                              ))}
                            </div>
                          </div>
                        </Row>
                      </div>
                      <div className="p-3 bg-light">
                        <Row className="justify-content-between">
                          <Col md={8}>
                            <div className="d-flex mb-0">
                              <div className="flex-shrink-0">
                                <i className="mdi mdi-map-marker text-primary me-1"></i>
                              </div>
                              <p className="text-muted mb-0">
                                {formatAddress(jobDetails.address)}
                              </p>
                            </div>
                          </Col>
                          <Col lg={4} md={3}>
                            <div className="d-flex mb-0">
                              <div className="flex-shrink-0">
                                <i className="uil uil-clock-three text-primary me-1"></i>
                              </div>
                              <p className="text-muted mb-0">
                                {jobDetails.durationSincePosting}
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  // <div
                  //   key={index}
                  //   className={
                  //     jobDetails.addclassNameBookmark === true
                  //       ? "job-box bookmark-post card mt-4"
                  //       : "job-box card mt-4"
                  //   }
                  // >
                  //   <div className="p-4">
                  //     <Row>
                  //       <Col lg={1}>
                  //         <Link to={`/companydetails/${jobDetails.company.id}`}>
                  //           <img
                  //             src={jobDetails.company.logo}
                  //             alt=""
                  //             className="img-fluid rounded-3"
                  //           />
                  //         </Link>
                  //       </Col>
                  //       <Col lg={10}>
                  //         <div className="mt-3 mt-lg-0">
                  //           <h5 className="fs-17 mb-1">
                  //             <Link
                  //               to={`/jobdetails/${jobDetails.id}`}
                  //               className="text-dark"
                  //             >
                  //               {jobDetails.title}
                  //             </Link>{" "}
                  //             <small className="text-muted fw-normal">
                  //               ({jobDetails.experience} Ans - Exp )
                  //             </small>
                  //           </h5>
                  //           <ul className="list-inline mb-0">
                  //             <li className="list-inline-item">
                  //               <p className="text-muted fs-14 mb-0">
                  //                 {jobDetails.company.name}
                  //               </p>
                  //             </li>
                  //             <li className="list-inline-item">
                  //               <p className="text-muted fs-14 mb-0">
                  //                 <i className="mdi mdi-map-marker"></i>
                  //                 {jobDetails.address.city},{" "}
                  //                 {jobDetails.address.country}
                  //               </p>
                  //             </li>
                  //           </ul>
                  //           <div className="mt-2">
                  //             <span
                  //               className={
                  //                 jobDetails.contractType.includes("CDI")
                  //                   ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                  //                   : jobDetails.contractType.includes("CDD")
                  //                   ? "badge bg-danger-subtle text-danger fs-13 mt-1 mx-1"
                  //                   : ""
                  //               }
                  //             >
                  //               {jobDetails.contractType.join(", ")}
                  //             </span>
                  //           </div>
                  //         </div>
                  //       </Col>
                  //     </Row>
                  //   </div>
                  //   <div className="p-3 bg-light">
                  //     <div className="row justify-content-between">
                  //       <Col md={8}>
                  //         <div>
                  //           <ul className="list-inline mb-0">
                  //             <li className="list-inline-item">
                  //               <p className="text-muted fs-14 mb-0">
                  //                 <i className="uil uil-wallet"></i>{" "}
                  //                 {jobDetails.minSalary} -{" "}
                  //                 {jobDetails.maxSalary}
                  //               </p>
                  //             </li>
                  //           </ul>
                  //         </div>
                  //       </Col>
                  //     </div>
                  //   </div>
                  // </div>
                ))
              )}
            </div>
            {job.content.length > 2 && (
              <div className="text-center mt-4">
                <Link
                  to={`/jobs?companyId=${selectedDetails.id}`}
                  className="primary-link form-text"
                >
                  Voir plus <i className="mdi mdi-arrow-right"></i>
                </Link>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightSideContent;
