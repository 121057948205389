import React, { useEffect } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import JobDetailsDescription from "./JobDetailsDescription";
import JobVacancyPost from "./JobVacancyPost";
import RightSideContent from "./RightSideContent";
import { fetchJobDetails } from "../../../redux/job/action";
import { useParams } from "react-router-dom";
import Section from "./Section";
import { useDispatch, useSelector } from "react-redux";

const JobDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  document.title = "Job Details | RecruTech";
  const { selectedDetails } = useSelector((state) => state.jobReducer);
  useEffect(() => {
    // Utilisez la valeur de la page actuelle ici
    dispatch(fetchJobDetails(id));
    window.scrollTo(0, 0);
  }, [dispatch, id]);
  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            {selectedDetails ? (
              <>
                <Col lg={8}>
                  <JobDetailsDescription selectedDetails={selectedDetails} />
                  <JobVacancyPost selectedDetails={selectedDetails} />
                </Col>
                <Col lg={4} className="mt-4 mt-lg-0">
                  <RightSideContent selectedDetails={selectedDetails} />
                </Col>
              </>
            ) : (
              <Col lg={12}>
                <div className="text-center mt-5">
                  <Spinner
                    color="primary"
                    style={{ width: "3rem", height: "3rem" }}
                  />
                </div>
                <div className="text-center mt-5">
                  <p>Chargement des détails du poste...</p>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobDetails;
